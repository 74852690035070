import React from "react" 

import Layout from "../components/layout"
import SEO from "../components/seo"
import quartz from "../assets/img/quartz.jpg"
import equipe from "../assets/img/equipe.jpg"
import citationLogo from "../assets/img/citation-logo.jpg"

const NousSommesPage = () => (
  <Layout>
    <SEO title="qui sommes nous" />
    <h1 class="text-center text-2xl font-bold mb-8 md:mt-10 md:mx-40 mx-3 pb-5 divider">Ste Corsaf sarl</h1>
    <div class="grid grid-cols-1 md:grid-cols-2 mt-8 gap-8 md:mx-40 md:my-5 mx-3">
      <div>
      <iframe width="100%" height="304" title="qui sommes nous" src="https://www.youtube.com/embed/4p95bvrCCq8" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
      </div>
      <div>
        <h4 class="text-bold text-center font-bold mb-5 leading-8 uppercase">SPÉCIALISTE EN TRANSFORMATION DU Solid surface, RÉSINE À GRANDE RÉSISTANCE</h4>
        <p>
        La société CORSAF est l’un des leaders Marocains spécialisé dans :
        <ul class="list-disc pl-5">
          <li>La transformation du Solid Surface (marque leader de résine acrylique);</li>
          <li>Le Quartz</li>
        </ul>
        Avec notre grande expérience dans la transformation des solid surfaces Corsaf vous propose des prestations de qualité en matière d’aménagement intérieur entre autres :
        </p>
      </div>
    </div>
    <div class="grid grid-cols-1 md:grid-cols-3 mt-8 gap-8 md:mx-40 md:my-5 mx-3 shadow-lg">
     <div class="flex items-center">
        <svg xmlns="http://www.w3.org/2000/svg" class="h-14 mx-4" viewBox="0 0 20 20" fill="currentColor">
          <path d="M10.707 2.293a1 1 0 00-1.414 0l-7 7a1 1 0 001.414 1.414L4 10.414V17a1 1 0 001 1h2a1 1 0 001-1v-2a1 1 0 011-1h2a1 1 0 011 1v2a1 1 0 001 1h2a1 1 0 001-1v-6.586l.293.293a1 1 0 001.414-1.414l-7-7z" />
        </svg>
          <p>Dans l’habitat (cuisines, salles de bains, habillages muraux, objets design…)</p>
        </div>
     <div class="flex items-center">
        <svg xmlns="http://www.w3.org/2000/svg" class="h-14 mx-4" viewBox="0 0 20 20" fill="currentColor">
          <path d="M2 10.5a1.5 1.5 0 113 0v6a1.5 1.5 0 01-3 0v-6zM6 10.333v5.43a2 2 0 001.106 1.79l.05.025A4 4 0 008.943 18h5.416a2 2 0 001.962-1.608l1.2-6A2 2 0 0015.56 8H12V4a2 2 0 00-2-2 1 1 0 00-1 1v.667a4 4 0 01-.8 2.4L6.8 7.933a4 4 0 00-.8 2.4z" />
        </svg>
          <p>Dans le tertiaire (sanitaires PMR, banques d’accueil…)</p>
        </div>
     <div class="flex items-center">
        <svg xmlns="http://www.w3.org/2000/svg" class="h-14 mx-4" viewBox="0 0 20 20" fill="currentColor">
          <path d="M8 9a3 3 0 100-6 3 3 0 000 6zM8 11a6 6 0 016 6H2a6 6 0 016-6zM16 7a1 1 0 10-2 0v1h-1a1 1 0 100 2h1v1a1 1 0 102 0v-1h1a1 1 0 100-2h-1V7z" />
        </svg>
          <p>Dans l’univers de la santé (cabinets dentaires, hôpitaux…)</p>
     </div>
    </div>
    <div class="grid grid-cols-1 md:grid-cols-2 mt-8 gap-8 md:mx-40 md:my-15 mx-3">
      <div class="flex items-center">
        <p>CORSAF DESIGN vous propose ses services pour tous travaux de peinture en Poudre qui sont actuellement la solution peinture la plus compétitive. Elles permettent une mise en œuvre simplifiée et écologique pour réaliser des finitions uniformes et durables.</p>
      </div>
      <div>
        <img src={quartz} alt="quartz" class="w-full" />
      </div>
    </div>
    <div class="w-full bg-gray-800">
      <div class="grid grid-cols-1 md:grid-cols-2 mt-8 gap-8 md:mx-40 md:my-15 mx-3">
        <div class="flex items-center text-white">
           <div> <h1 class="text-center text-lg font-bold mb-8"> NOTRE EQUIPE </h1>
              <p class="text-justify">
              Notre équipe d’usinage se compose des techniciens diplômés hautement qualifiés et maîtrisant parfaitement la transformation de Solid Surface ainsi qu’un atelier équipé des toutes dernières technologies nécessaires à la transformation en petites et grandes séries de cette résine de acrylique. Corsaf Design assure un suivi de vos projets de A à Z avec un seul interlocuteur. Toutes nos créations sont à 100% des fabrications Marocaine ! Après réception du projet ou visite sur site, nous vous soumettrons dans les plus brefs délais un devis gratuit, personnalisé et détaillé.
              </p>
              </div>
        </div>
        <div class="flex items-center">
          <img src={equipe} alt="equipe" class="w-full py-6 rounded" />
        </div>
      </div>
    </div>
    <div class="grid grid-cols-1 mt-8 gap-8 md:mx-40 md:my-15 mx-3">
      <div>
        <h1 class="text-center text-2xl pb-4 font-bold mb-8 md:mt-10 divider">NOS QUALITES</h1>
        <p class="text-justify">
          Quel que soit votre projet Solid Surface , n’hésitez pas à nous consulter… vous avez la garantie d’une excellente qualité Corsaf Design collabore régulièrement avec des cabinets d’architectes qui sollicitent notre savoir-faire et notre expertise.
        </p>
      </div>
    </div>
    <div class="grid grid-cols-1 md:grid-cols-3 mt-8 gap-8 md:mx-40 md:my-15 mx-3">
      <div class="flex items-center">
      <svg xmlns="http://www.w3.org/2000/svg" class="h-20 mx-4" viewBox="0 0 20 20" fill="currentColor">
      <path fillRule="evenodd" d="M11.49 3.17c-.38-1.56-2.6-1.56-2.98 0a1.532 1.532 0 01-2.286.948c-1.372-.836-2.942.734-2.106 2.106.54.886.061 2.042-.947 2.287-1.561.379-1.561 2.6 0 2.978a1.532 1.532 0 01.947 2.287c-.836 1.372.734 2.942 2.106 2.106a1.532 1.532 0 012.287.947c.379 1.561 2.6 1.561 2.978 0a1.533 1.533 0 012.287-.947c1.372.836 2.942-.734 2.106-2.106a1.533 1.533 0 01.947-2.287c1.561-.379 1.561-2.6 0-2.978a1.532 1.532 0 01-.947-2.287c.836-1.372-.734-2.942-2.106-2.106a1.532 1.532 0 01-2.287-.947zM10 13a3 3 0 100-6 3 3 0 000 6z" clipRule="evenodd" />
    </svg>
        <p>Nous proposons des solutions sur-mesure à tous les projets de construction ou de rénovation selon votre budget.</p>
      </div>
      <div class="flex items-center">
      <svg xmlns="http://www.w3.org/2000/svg" class="h-20 mx-4" viewBox="0 0 20 20" fill="currentColor">
      <path fill-rule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-8-3a1 1 0 00-.867.5 1 1 0 11-1.731-1A3 3 0 0113 8a3.001 3.001 0 01-2 2.83V11a1 1 0 11-2 0v-1a1 1 0 011-1 1 1 0 100-2zm0 8a1 1 0 100-2 1 1 0 000 2z" clip-rule="evenodd" />
    </svg>
        <p>Nous saurons répondre à toutes vos questions. N’hésitez pas à nous contacter par mail pour nous faire parvenir vos projets.</p>
      </div>
      <div class="flex items-center">
      <svg xmlns="http://www.w3.org/2000/svg" class="h-20 mx-4" viewBox="0 0 20 20" fill="currentColor">
        <path fillRule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clipRule="evenodd" />
      </svg>
        <p>Nous les étudierons avec minutie et nous vous apporterons des solutions sur-mesure répondant à vos exigences.</p>
      </div>
    </div>
    <div class="w-full citation-bg">
      <div class="grid grid-cols-1 mt-8 gap-8 md:mx-40 md:my-20 mx-3">
        <img src={citationLogo} alt="corsaf logo" class="mx-auto rounded-full mt-3" />
        <p class="text-white text-center leading-8">" Je me présente, je m’appelle MOHAMED SAFEAA. Je dirige la société CORSAF DESIGN, entreprise spécialisée dans la transformation du Solid Surface et la peinture en Poudre Epoxy. Ce site s’adresse aux architectes, designers, à tous les amoureux du solid surface au résine minérale, à mes confrères qui sont les bienvenus, mais aussi aux amoureux des belles choses, ou tout simplement au visiteur perdu dans les méandres du net, qui peut stopper là quelques instants, son parcours afin d’admirer tout simplement ! Un lieu pour puiser des idées, de l’inspiration, et découvrir les limites que chacun a pu se donner en jouant avec les couleurs, les formes et le mixte des matériaux…." </p>
        <p class="text-center text-yellow-100 mb-3">
          MOHAMED SAFEAA Directeur Général - CORSAF
        </p>
      </div>
    </div>
     
  </Layout>
)

export default NousSommesPage
